#graph_container {
  margin-top: 5px;
  position: relative;
}
#graph {
  outline: solid var(--light-gray) 2px;
  position: absolute;
}

#graph_grid_layer {
  opacity: 0.5;
}

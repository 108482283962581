.node_search_menu {
  width: 100%;
  max-height: calc(6 * 40px);
  overflow-y: auto;
  position: absolute;
  top: 40px;
  background: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.node_search_item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  font-size: 16px;
  transition: background 0.25s ease;
}
.node_search_item_selected {
  background: var(--light-blue);
}
.node_search_item:hover,
.node_search_item_selected:hover {
  cursor: pointer;
}
.node_search_name {
  flex-grow: 1;
  font-weight: 500;
}
.node_search_count {
  color: var(--gray);
  font-size: 0.75em;
  font-weight: 500;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 45px;
  vertical-align: middle;
}
.node_search_count svg {
  height: 25px;
  margin-right: 3px;
  vertical-align: middle;
}

.node_search_form {
  display: block;
  width: 100%;
  height: 40px;
  margin: 0 auto;
  text-align: left;
  position: relative;
}

.node_search_form_label {
  position: absolute;
  margin-bottom: 5px;
  bottom: 100%;
}

.source_node_block .node_search_form {
  margin-top: 10px;
}

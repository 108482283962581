#root {
  text-align: center;
}

.table_attic {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0;
}
.table_attic > *:first-child:not(:last-child) {
  flex-grow: 1;
  padding-left: 10px;
}
@media screen and (max-width: 640px) {
  .table_attic > *:first-child:not(:last-child) {
    width: 100%;
    order: 1;
    margin-top: 5px;
  }
}
table tr th {
  height: 40px;
}

.node_results_content{
  padding-left: 10px;
}
.node_results_table{
  margin-top: 5px;
  margin-bottom: 10px;
}

.node-search-block{
  padding: 20px 0;
}
.graph_block{
  margin-top: 50px;
}

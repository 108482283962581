.collapsible_section {
  margin: 20px auto;
}
.collapsible_section_button {
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  color: var(--black);
  position: relative;
}
.collapsible_section_button:before {
  content: '';
  position: absolute;
  height: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--light-gray);
  opacity: 1;
  transition: opacity 0.25s ease;
}
.collapsible_section_button:hover:before,
.collapsible_section_button:focus:before {
  opacity: 0;
}
.collapsible_section[data-collapsed='true'] > *:not(:first-child) {
  display: none;
}
.collapsible_section_button {
  display: flex;
  align-items: center;
}
.collapsible_section_button > div {
  flex-grow: 1;
}

.node_search_round_button {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 10px !important;
  padding: 0 !important;
}
.node_search_round_button svg {
  transform: rotate(90deg);
}

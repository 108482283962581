.tooltip {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  max-width: 320px;
  min-height: 30px;
  padding: 5px;
  background: var(--black);
  color: var(--white);
  font-size: 0.75em;
  transform: translate(0, calc(-100% - 5px));
  user-select: none;
  pointer-events: none;
  z-index: 99999;
}
@media (hover: none) {
  .tooltip {
    display: none;
  }
}

.node_search_search_button {
  margin: 0;
  font-weight: 400;
  width: 100%;
  background: var(--blue);
  color: var(--white);
}
.node_search_search_button[disabled]{
  background: var(--light-gray);
  color: var(--black);
}

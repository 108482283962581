.node_group {
  position: relative;
  margin-top: 10px;
}

.node_title {
  line-height: 1.5rem;
  text-align: left;
  color: var(--black);
}

.node_menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
  max-height: calc(6 * 40px);
  overflow-y: auto;
  background: var(--white);
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  margin-bottom: 10px;
  box-sizing: border-box;
}

.node_icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  z-index: 10;
}

.node_item_selected {
  background: var(--light-blue);
}

.node_item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 49.5%;
  max-width: 50%;
  height: 40px;
  padding: 0 10px;
  margin: 3px 0px;
  font-size: 16px;
  transition: background 0.25s ease;
  border: 1px solid var(--light-gray);
  box-sizing: border-box;
}

.node_name {
  flex-grow: 1;
  font-weight: 500;
  text-align: left;
}

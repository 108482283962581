.info_table {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.info_table_key {
  width: 15%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info_table_value {
  width: 35%;
}
.info_table_value .dynamic_field {
  padding: 0 10px 0 5px;
}
@media screen and (max-width: 640px) {
  .info_table_key {
    width: 30%;
  }
  .info_table_value {
    width: 70%;
  }
}

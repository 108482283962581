.dynamic_field {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  word-break: break-word;
}
.dynamic_field[data-expanded='true'],
.dynamic_field[data-expanded='true'] .nowrap {
  overflow: unset !important;
  white-space: normal !important;
  text-overflow: unset !important;
}
.dynamic_field:hover {
  cursor: text;
}
th > .dynamic_field,
td > .dynamic_field {
  min-height: 25px;
}

.node_search_overlay {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  background: var(--light-gray);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  padding-right: 35px;
  pointer-events: none;
}
.node_search_input {
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--black);
  /* padding: 10px; */
  /* padding-right: 35px; */
  font-size: 1em;
  transition: background 0.25s ease;
}
.node_search_icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}
